import React, { useEffect, useState } from 'react';
import './style.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AvatarFoto from '../../Components/AvatarFoto';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GlobalContext } from '../../GlobalContext';
import Loading from '../../Components/Loading';
import { FcNext } from 'react-icons/fc';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Stars } from '../../Components/Stars';
import BotaoDarkMode from '../../Components/BotaoDarkMode';
import { Header } from '../../Components/Header';
import { HeaderProfile } from '../../Components/HeaderProfile';
const { REACT_APP_URL_API } = process.env;

const Perfil = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { item } = React.useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  let [data, setData] = React.useState(null);

  let [sorteio, setSorteio] = React.useState(null);

  let [evento, setEvento] = React.useState(null);

  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  const teste = cliente_id;

  var raw2 = JSON.stringify({
    client_id: teste,
  });

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);
  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteio(json);
      });
  }, []);

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/evento/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setEvento(json);
      });
  }, []);

  if (data === null) return null;
  if (sorteio === null) return null;

  const numberSorteio = sorteio[0]?.response.filter(
    (item) => item.controle[0] === 'show',
  ).length;

  const numberVoucher = data[0]?.response.voucher.filter(
    (item) => item.controle[0] === 'show',
  ).length;

  const numberEvent = evento[0]?.response.filter(
    (item) => item.controle[0] === 'show',
  ).length;

  setTimeout(() => {
    setLoading(false);
  }, 800);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div>
      <HeaderProfile />
      <div className="MainGrid">
        <div className="container-foto container-foto-perfil">
          <div>
            {' '}
            <AvatarFoto />
          </div>

          <div>
            {' '}
            <p
              className="nome-perfil"
              style={{ color: data[0].response.cor_custom }}
            >
              {item.full_name}
            </p>
            <p className="profile-text">
              <b>{numberSorteio}</b> Sorteios
            </p>
            <p className="profile-text">
              <b>{numberVoucher}</b> Vouchers
            </p>
            <p className="profile-text">
              <b>{numberEvent}</b> Eventos
            </p>
          </div>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontWeight: '150',
                color: 'gray',
                marginTop: '20px',
              }}
            >
              {item.company}
            </p>

            <BotaoDarkMode />

            <br />
            {/* <button className="btn-edit-perfil">
              <a>Editar perfil</a>
            </button> */}

            {cliente_id === 'hashtagsal' ? (
              <span>
                {item.company !== 'Apoiadores #Sal' ? (
                  <a
                    href="https://apoia.se/hashtagsal"
                    style={{
                      color: data[0].response.cor_custom,
                      textAlign: 'center',
                    }}
                    target="_blank"
                    className="link-apoiador"
                  >
                    <FavoriteBorderIcon fontSize="small" /> Seja um apoiador
                  </a>
                ) : null}
              </span>
            ) : null}

            {/* 
            {item.company !== 'Apoiadores #Sal' ? (
          <MenuItem>
            <ListItemIcon>
              <FavoriteBorderIcon fontSize="small" /> Seja um apoiador
            </ListItemIcon>

            <a
              href="https://apoia.se/hashtagsal"
              style={{ color: '#111' }}
              target="_blank"
            ></a>
          </MenuItem>
        ) : null} */}
          </div>
        </div>
        <hr className="linha" />
      </div>
      <Box
        sx={{
          maxWidth: '930px',
          margin: '0px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
          zIndex: 'none',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable prevent tabs example"
          sx={{
            zIndex: 'none',
            position: 'relative',
          }}
        >
          <Tab label=" Sorteios" style={{ color: 'var(--secondary)' }} />
          <Tab label="Vouchers" style={{ color: 'var(--secondary)' }} />
          <Tab label="Eventos" style={{ color: 'var(--secondary)' }} />
        </Tabs>

        {value === 0 && (
          <div className="MainGrid" style={{ marginTop: '5px' }}>
            {sorteio[0].response.map((item) => (
              <div key={item.id}>
                {item.controle[0] == 'show' && (
                  <div className="container-sorteio-perfil">
                    <div>
                      {' '}
                      {item.imagem ? (
                        <img
                          src={`${REACT_APP_URL_API}` + item.imagem}
                          alt=""
                          className="img-sorteio-perfil"
                        />
                      ) : (
                        <img
                          src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                          alt=""
                          className="img-sorteio-perfil"
                        />
                      )}
                    </div>

                    {item.sorteio_realizado == true ? (
                      <div
                        style={{ textAlign: 'center', margin: 'auto' }}
                        className="participando"
                      >
                        {' '}
                        <h2>{item.nome}</h2>
                        <p>{item.data_sorteio}</p>
                        <a href={`${item.id}/ganhador`}>
                          <span>
                            {' '}
                            Conhecer o ganhador <FcNext />{' '}
                          </span>
                        </a>
                      </div>
                    ) : (
                      <div
                        style={{ textAlign: 'center', margin: 'auto' }}
                        className="participando"
                      >
                        {' '}
                        <h2>{item.nome}</h2>
                        <p>{item.data_sorteio}</p>
                        <p>Boa sorte, {item.controle[1]}!</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {value === 1 && (
          <div className="MainGrid" style={{ marginTop: '5px' }}>
            {data.map((item, index) => (
              <div key={index}>
                {item.response.voucher.map((cupom) => (
                  <div key={cupom.id}>
                    {cupom.controle[0] == 'show' && (
                      <div className="container-sorteio-perfil">
                        <div style={{ display: 'flex' }}>
                          {!cupom.custom_logo && 'null' ? (
                            <img
                              src={
                                `${REACT_APP_URL_API}/media/` +
                                cupom.logo_empresa_parceira
                              }
                              id={cupom.id}
                              alt=""
                              className="logo-cupom-perfil"
                            />
                          ) : (
                            <img
                              src={`${REACT_APP_URL_API}` + cupom.custom_logo}
                              id={cupom.id}
                              alt=""
                              className="logo-cupom-perfil"
                            />
                          )}
                        </div>
                        <div style={{ textAlign: 'center', margin: 'auto' }}>
                          {' '}
                          <h2> {cupom.desconto} </h2>
                          <p>{cupom.descricao}</p>
                          <p> valido até {cupom.controle[2]}</p>
                          {/* <Stars /> */}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        {value === 2 && (
          <div className="MainGrid" style={{ marginTop: '5px' }}>
            {evento[0].response.map((item) => (
              <div key={item.id}>
                {item.controle[0] == 'show' && (
                  <div className="container-sorteio-perfil">
                    <div>
                      {' '}
                      {item.imagem ? (
                        <img
                          src={`${REACT_APP_URL_API}` + item.imagem}
                          alt=""
                          className="img-sorteio-perfil"
                        />
                      ) : (
                        <img
                          src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                          alt=""
                          className="img-sorteio-perfil"
                        />
                      )}
                    </div>

                    {item.evento_realizado == true ? (
                      <div
                        style={{ textAlign: 'center', margin: 'auto' }}
                        className="participando"
                      >
                        {' '}
                        <h2>{item.nome}</h2>
                        <p>{item.data_evento}</p>
                      </div>
                    ) : (
                      <div
                        style={{ textAlign: 'center', margin: 'auto' }}
                        className="participando"
                      >
                        {' '}
                        <h2>{item.nome}</h2>
                        <p>{item.descricao}</p>
                        <p>{item.data_evento}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </Box>
    </div>
  );
};

export default Perfil;
