import React, { Component } from 'react';
import Stage from './Stage';
import { AutoSpinCounter, SpinCounter } from './countDown/Timers';
import './style.css';
import Slot from '../../Slot';
import { GlobalContext } from '../../../GlobalContext';

class SlotMachine extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.possibleSymbols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    this.numOfReels = 1;
    this.wheelGenerators = [];
    this.state = {
      stage: Stage.READY,
      wheels: this.getRandomReels(),
    };
    this.timers = {};
  }

  componentDidMount() {
    this.timers.autoStart = setTimeout(() => this.start(true), 100);
  }

  componentWillUnmount() {
    Object.values(this.timers).forEach((timerId) => clearInterval(timerId));
  }

  say(text) {
    this.setState({ text });
  }

  *wheelGenerator(symbols) {
    let index = this.pickRandomIndex(symbols);
    while (true) {
      yield symbols[index];
      index = index === symbols.length - 1 ? 0 : index + 1;
    }
  }

  getRandomReels() {
    return Array(this.numOfReels)
      .fill()
      .map((wheel, idx) => {
        this.wheelGenerators.push(this.wheelGenerator(this.possibleSymbols));
        return this.wheelGenerators[idx].next().value;
      });
  }

  pickRandomIndex(collection) {
    return Math.floor(Math.random() * collection.length);
  }

  pickRandomItem(collection) {
    const randomIndex = this.pickRandomIndex(collection);
    return collection[randomIndex];
  }
  calculatePrize(symbols) {
    const jackpot = '💰 Congratulations! You won the Jackpot of 100 coins! 💰';
    const secondPrize = 'Recebendo resultado...';
    const thirdPrize = "You won 10 coins. Don't worry, just try again!";
    if (symbols[0] === symbols[1] && symbols[1] === symbols[8]) {
      return jackpot;
    }

    if (symbols[0] === symbols[1] || symbols[1] === symbols[8]) {
      return secondPrize;
    }

    if (symbols[0] === symbols[8]) {
      return thirdPrize;
    }

    return 0;
  }

  start(isAuto = false) {
    clearTimeout(this.timers.autoStart);
    this.timers.spinner = setInterval(() => {
      const newReels = this.state.wheels.map(
        (wheel, idx) => this.wheelGenerators[idx].next().value,
      );
      this.setState({
        wheels: newReels,
      });
    }, 40);
    this.setState({
      stage: Stage.SPINNING,
    });
    this.say(isAuto ? AutoSpinCounter : SpinCounter);
    this.timers.autoStop = setTimeout(() => this.stop(), 16000);
  }

  stop(isAuto = false) {
    clearInterval(this.timers.spinner);
    clearTimeout(this.timers.autoStop);
    const finalReels = Array(this.numOfReels)
      .fill()
      .map(() => this.pickRandomItem(this.possibleSymbols));
    const prize = this.calculatePrize(finalReels);
    this.setState({
      wheels: finalReels,
      stage: Stage.READY,
    });
    const prizeText = prize ? prize : ' ';
    this.say(`${isAuto ? '' : ''} ${prizeText}`);

    var machineDiv = document.querySelector('.machine');
    var machineMessagem = document.querySelector('.machine-message');
    var machineBase = document.querySelector('.base');
    var machineVencedor = document.querySelector('.vencedor');

    var resultado = function () {
      machineDiv.style.background =
        'linear-gradient(#111 0%, #333 31%, #333 72%, #111 100%)';
      machineMessagem.style.display = ' none';
      machineBase.style.display = ' none';
      machineVencedor.style.display = ' block';
    };
    setTimeout(resultado, 1000);
    setTimeout(() => {
      return window.location.reload();
    }, 6000);
  }
  render() {
    const cliente = this.context;

    var vencedor = localStorage.getItem('vencedor');

    return (
      <div>
        <div className="MainGrid">
          <div className="machine ">
            <Slot />
            <p className="vencedor parpadea">{vencedor}</p>

            <p className="machine-message">{this.state.text}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SlotMachine;
