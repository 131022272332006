import React, { useEffect, useState } from 'react';
import './style.css';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Countdown from '../../../Countdown';
import SlotMachine from '../SlotMachine/SlotMachine';
import Resultado from '../Resultado';
import { GlobalContext } from '../../../GlobalContext';

const { REACT_APP_URL_API } = process.env;

const Time = () => {
  const [data, setData] = useState([]);
  const [sorteio, setSorteio] = useState([]);
  const { cliente_id, id } = useParams();
  const navigate = useNavigate();
  const [hasNextSorteio, setHasNextSorteio] = useState(null); // Verifica se há próximo sorteio
  const [sorteio2, setSorteio2] = useState([]); // Armazena dados adicionais do sorteio (se necessário)
  const { item } = React.useContext(GlobalContext);

  const empresaId = item?.company_id; // Certifique-se que item está definido

  // Busca todos os sorteios disponíveis
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    const raw = JSON.stringify({ client_id: cliente_id });

    fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: raw,
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        const nextSorteioExists = json[0]?.response?.some(
          (item) => item.id !== parseInt(id),
        );
        setHasNextSorteio(nextSorteioExists || false);
      })
      .catch((err) => console.error('Erro ao buscar sorteios:', err));
  }, [cliente_id, id]);

  // Busca os dados do sorteio atual
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    const raw = JSON.stringify({ client_id: cliente_id, sorteio_id: id });

    fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: raw,
    })
      .then((response) => response.json())
      .then((json) => setSorteio(json))
      .catch((err) =>
        console.error('Erro ao buscar controle do sorteio:', err),
      );
  }, [cliente_id, id]);

  // Função para redirecionar para o próximo sorteio
  const goToNextSorteio = () => {
    const nextSorteio = data[0]?.response.find(
      (item) =>
        item.id !== parseInt(id) &&
        (item.sorteio_exclusivo === null ||
          item.sorteio_exclusivo === empresaId),
    );
    if (nextSorteio) {
      navigate(`/${cliente_id}/${nextSorteio.id}/painel`);
    } else {
      console.error('Nenhum próximo sorteio encontrado.');
    }
  };

  // Lógica para determinar a próxima ação com base na tela atual
  const tempo = parseInt(sorteio[0]?.timer);

  useEffect(() => {
    if (!isNaN(tempo) && tempo > 0 && sorteio[0]?.screen === 'during') {
      const timer = setTimeout(() => {
        goToNextSorteio();
      }, tempo * 1000);

      return () => clearTimeout(timer); // Limpa o timer ao desmontar
    }
  }, [tempo, sorteio, goToNextSorteio]);

  // Renderiza a tela de resultado com redirecionamento automático
  if (sorteio[0]?.screen === 'after') {
    return <Resultado goToNextSorteio={goToNextSorteio} />;
  }

  // Renderiza a máquina de slots
  if (sorteio[0]?.screen === 'during') {
    return <SlotMachine />;
  }

  // Dados do sorteio atual
  const nomeSorteio = data[0]?.response.find(
    (item) => item.id === parseInt(id),
  )?.nome;
  const fotoSorteio = data[0]?.response.find(
    (item) => item.id === parseInt(id),
  )?.imagem;
  const dataSorteio = data[0]?.response.find(
    (item) => item.id === parseInt(id),
  )?.data_sorteio;

  return (
    <div>
      <div className="div-sorteio-atual card-painel box">
        {fotoSorteio ? (
          <img
            src={`${REACT_APP_URL_API}${fotoSorteio}`}
            alt="Sorteio"
            style={{ objectFit: 'scale-down' }}
          />
        ) : (
          <img
            src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
            alt="Imagem padrão"
          />
        )}
        <div>
          <h1
            className="title-sorteio-timer-painel"
            style={{ color: 'var(--title)' }}
          >
            {nomeSorteio}
          </h1>
          <p>{dataSorteio}</p>
        </div>
      </div>

      {!isNaN(tempo) && tempo > 0 && (
        <Countdown datetime={new Date(Date.now() + tempo * 1000).toString()} />
      )}
    </div>
  );
};

export default Time;
