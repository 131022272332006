import { Post } from '../Post';
import { Story } from '../Story';
import './style.css';
export function Layout() {
  return (
    <>
      <div className="MainGrid">
        <div className="firstColumn">
          {/* <div className="box">
            {' '}
            <Story />{' '}
          </div> */}

          <div className="box" style={{ margin: '10px auto' }}>
            <Post />
          </div>
        </div>
      </div>
    </>
  );
}
