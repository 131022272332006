import React, { useEffect, useState } from 'react';
import './style.css';
import { Header } from '../../Components/Header';
import { Link, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import Countdown from '../../Countdown';
import Time from '../SorteioOnline/Time';

const { REACT_APP_URL_API } = process.env;

const SorteioPainel = () => {
  let [data, setData] = React.useState(null);
  let [sorteio, setSorteio] = React.useState(null);

  let [showBotao, setShowBotao] = React.useState(null);
  let [showBotaoFalse, setShowBotaoFalse] = React.useState(null);

  let [sorteioPanel, setSorteioPanel] = React.useState(null);

  const { cliente_id, id } = useParams();
  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  const teste = cliente_id;

  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: id,
  });

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  const today = new Date();

  let dataFormatada = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(today);

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteio(json);
        setShowBotao(
          json[0]?.response.filter(
            (item) =>
              dataFormatada == item.data_sorteio.slice(0, -7).toLowerCase() &&
              !item.sorteio_realizado &&
              item.controle[0] == 'show',
          ).length,
        );

        setShowBotaoFalse(
          json[0]?.response.filter(
            (item) =>
              dataFormatada == item.data_sorteio.slice(0, -7).toLowerCase() &&
              item.sorteio_realizado &&
              item.controle[0] == 'show',
          ).length,
          // console.log(
          //   'not show',
          //   json[0]?.response.filter(
          //     (item) =>
          //       dataFormatada == item.data_sorteio.slice(0, -7).toLowerCase() &&
          //       !item.sorteio_realizado &&
          //       item.controle[0] == 'show',
          //   ).length,
          // ),
        );
      });
  }, []);

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteioPanel(json);
      });
  }, []);

  // const tempo = parseInt(sorteioPanel[0]?.timer);

  if (data === null) return null;
  if (sorteio === null) return null;
  if (sorteioPanel === null) return null;

  {
    console.log('sorteio', sorteio);
  }
  // const nomeSorteio = sorteio[0]?.response.filter((item) => item.id == id)[0]
  //   .nome;
  // const fotoSorteio = sorteio[0]?.response.filter((item) => item.id == id)[0]
  //   .imagem;

  const dataSorteio = sorteio[0]?.response
    .filter((item) => item.id == id)[0]
    .data_sorteio.slice(0, -7)
    .toLowerCase();
  const dt = new Date(Date.now() + sorteioPanel[0]?.timer * 1000).toString();

  // let data1 = new Date(dt);
  // let dataFormatadaSorteio =
  //   data1.getDate() + '/' + (data1.getMonth() + 1) + '/' + data1.getFullYear();

  function compare(a, b) {
    return a.data_sorteio > b.data_sorteio
      ? 1
      : a.data_sorteio < b.data_sorteio
      ? -1
      : 0;
  }

  const [, match] = dataFormatada.match(/(\S+) /) || [];

  return (
    <div>
      <Header />
      <div className="div-alert-sorteio-painal">
        {/* <Alert severity="info">
          Informamos que, devido às regras do regulamento, não será possível ser
          sorteado em mais de um evento do mesmo dia.
        </Alert> */}
      </div>
      <div className="sorteio_grid">
        <div className="container-proximo ">
          {showBotao > 1 && dataSorteio == dataFormatada ? (
            <div className="text-proximo">
              <p>Proximos sorteios</p>
            </div>
          ) : null}
          {sorteio[0].response.sort(compare).map((item) => (
            <span key={item.id}>
              {/* {console.log('hoje', dataSorteio)} */}
              {item.controle[0] == 'show' &&
              item.sorteio_realizado == false &&
              dataFormatada == item.data_sorteio.slice(0, -7).toLowerCase() &&
              dataSorteio == dataFormatada ? (
                <span>
                  {item.id == id ? null : (
                    <a href={`/${cliente_id}/${item.id}/painel`}>
                      <div
                        className=" card-painel container-card-sorteio box"
                        style={{ maxHeight: '120px' }}
                      >
                        {item.imagem ? (
                          <img
                            src={`${REACT_APP_URL_API}` + item.imagem}
                            style={{ objectFit: 'scale-down' }}
                            alt=""
                          />
                        ) : (
                          <img
                            src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                            style={{ objectFit: 'scale-down' }}
                            alt=""
                          />
                        )}
                        <div className="conteudo-card conteudo-painel">
                          <h2>{item.nome}</h2>
                          <p>{item.data_sorteio}</p>
                        </div>
                      </div>
                    </a>
                  )}
                </span>
              ) : null}
            </span>
          ))}
        </div>

        <div className="div-time ">
          <Time />
        </div>

        <div className="container-encerrados">
          {showBotaoFalse ? (
            <div className="text-encerrados">
              <p>Sorteios Encerrados</p>{' '}
            </div>
          ) : null}

          {sorteio[0].response.map((item) => (
            <span key={item.id}>
              {item.controle[0] == 'show' &&
              item.sorteio_realizado == true &&
              dataFormatada == item.data_sorteio.slice(0, -7).toLowerCase() ? (
                <div
                  className=" card-painel container-card-sorteio box"
                  style={{ maxHeight: '120px' }}
                >
                  {item.imagem ? (
                    <img
                      src={`${REACT_APP_URL_API}` + item.imagem}
                      style={{ objectFit: 'scale-down' }}
                      alt=""
                    />
                  ) : (
                    <img
                      src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                      style={{ objectFit: 'scale-down' }}
                      alt=""
                    />
                  )}
                  <div className="conteudo-card conteudo-painel">
                    <h2>{item.nome}</h2>
                    <p>{item.data_sorteio}</p>
                  </div>
                </div>
              ) : null}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SorteioPainel;
