import React from 'react';
import { Header } from '../../../Components/Header';
import './style.css';
import happyIcon from '../../../Assets/undraw_happy.svg';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../../GlobalContext';

const { REACT_APP_URL_API } = process.env;

const Ganhador = () => {
  let [sorteio, setSorteio] = React.useState([]);
  let [sorteio2, setSorteio2] = React.useState([]);
  const { item } = React.useContext(GlobalContext);

  const { cliente_id } = useParams();
  const { id } = useParams();

  const teste = cliente_id;
  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: id,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteio(json);
      });
  }, []);

  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: id,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteio2(json);
      });
  }, []);

  if (sorteio === null) return null;
  if (sorteio2 === null) return null;
  const vencedor = sorteio2[0]?.response.filter((item) => item.id == id)[0]
    .usuario_sorteado;
  const foto = sorteio2[0]?.response.filter((item) => item.id == id)[0].imagem;

  {
    console.log(
      'vencedor',
      sorteio2[0]?.response.filter((item) => item.id == id)[0],
    );
  }

  return (
    <>
      <Header />

      <div className="MainGrid">
        <div className="container-resultado ">
          {item.full_name === sorteio[0]?.chosen_name ? (
            <div className="resultado ">
              <h1 style={{ marginTop: '100px' }}>
                Ganhador: {sorteio[0]?.chosen_name}{' '}
              </h1>
              <img src={happyIcon} alt="" style={{ width: '130px' }} />
              <img src={`${REACT_APP_URL_API}` + foto} alt="" />
              {/* <p className="text-ganhador">{sorteio[0]?.chosen_name}</p> */}
              <p className="text-desconto">
                🎉🎉 Parabéns! Você é o ganhador! 🎉🎉
              </p>
            </div>
          ) : (
            <div className="resultado ">
              <h1 style={{ marginTop: '100px' }}>
                Ganhador: {sorteio[0]?.chosen_name}{' '}
              </h1>
              <img src={`${REACT_APP_URL_API}` + foto} alt="" />

              {/* <p className="text-ganhador">{sorteio[0]?.chosen_name}</p> */}

              {/* <p className="text-desconto">Não foi dessa vez! 😭</p> */}

              {/* <a href="#" target="_blank">
                <button className="btn-desc">30% de desconto</button>
              </a> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Ganhador;
