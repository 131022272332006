import React, { useEffect } from 'react';
import './style.css';
import { Header } from '../../Components/Header';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';

const { REACT_APP_URL_API } = process.env;

const Mural = () => {
  const { cliente_id } = useParams();
  const [value, setValue] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [data, setData] = React.useState(null);
  const [cor, setCor] = React.useState(null);

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/mural/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
        setValue(idSeg);
      });
  }, []);

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setCor(json));
  }, []);

  if (cor === null) return null;
  if (data === null) return null;
  if (data.response === null)
    return (
      <div>
        <Header />
        <div className="div-stack-alert">
          <Stack
            sx={{ width: '85%', margin: ' auto', maxWidth: '500px' }}
            spacing={5}
          >
            <Alert severity="warning">
              Nenhum mural cadastrado no momento.
            </Alert>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ButtonLink to={`/${cliente_id}`} className="btn-return-alert">
                Voltar
              </ButtonLink>
            </div>
          </Stack>
        </div>
      </div>
    );

  const categoriasUnicas = [];
  const categoriasFilter = [];
  const categorias = data.map((item) => item.response).flat();
  const categoriasGeral = categorias.map((item) => {
    if (!categoriasUnicas.includes(item.segmento)) {
      categoriasUnicas.push(item.segmento);
      categoriasFilter.push(item.id);
    }
  });

  function compare(a, b) {
    if (a[1] < b[1]) {
      return -1;
    } else {
      return true;
    }
  }

  const parsed_array = categoriasUnicas.sort(compare).map((val) => {
    return JSON.stringify(val);
  });
  const filtered_array = parsed_array
    .filter((value, ind) => parsed_array.indexOf(value) == ind)
    .map((val) => {
      return JSON.parse(val);
    });

  const idSeg = filtered_array[0];

  if (value === null) return setValue(idSeg);

  return (
    <div>
      <Header />

      <div className="MainGrid">
        <div className="div-mural-img">
          {/* <LogoCliente /> */}
          <p>Mural</p>
        </div>
        <hr className="linha" />

        {data[0].response.map((seg) => (
          <Box
            sx={{
              maxWidth: '930px',
              margin: '0px auto',
              left: '1%',
              right: '1%',
              bgcolor: 'var(--background-main)',
              padding: '0px 10px',
            }}
            key={seg.id}
          >
            <div>
              {data[0].response.map((item) => (
                <span key={item.id}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="scrollable prevent tabs example"
                  >
                    {filtered_array.sort(compare).map((seg) => (
                      <Tab key={seg.id} label={seg[1]} value={seg[0]} />
                    ))}
                  </Tabs>
                </span>
              ))}
            </div>

            <div>
              {value === seg.segmento[0] && (
                <div>
                  <div className="MainGrid" style={{ marginBottom: '-60px' }}>
                    <div className="container-card-sorteio box card-mural">
                      <PhotoProvider>
                        <PhotoView src={`${REACT_APP_URL_API}` + seg.imagem}>
                          <img
                            src={`${REACT_APP_URL_API}` + seg.imagem}
                            alt=""
                          />
                        </PhotoView>
                      </PhotoProvider>
                      <div className="conteudo-card">
                        <h2>{seg.titulo}</h2>

                        <p>{seg.descricao}</p>

                        {seg.link_externo ? (
                          <button
                            id="btn"
                            className="btn-participar"
                            style={{
                              background: `linear-gradient(135deg, ${cor[0].response.cor_custom} 75%, ${cor[0].response.cor_custom2} 100%)`,
                            }}
                          >
                            <a href={seg.link_externo} target="_blank">
                              Participar
                            </a>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default Mural;
