import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GlobalContext } from '../../../GlobalContext';
import './style.css';

const { REACT_APP_URL_API } = process.env;

const Resultado = ({ goToNextSorteio }) => {
  const [sorteio, setSorteio] = useState([]);
  const [sorteio2, setSorteio2] = useState([]);
  const [redirectTimer, setRedirectTimer] = useState(10); // Timer inicial
  const [isCancelled, setIsCancelled] = useState(false); // Controle do cancelamento
  const [hasNextSorteio, setHasNextSorteio] = useState(null); // Controle para próximo sorteio
  const { item } = React.useContext(GlobalContext);
  const [nextSorteioId, setNextSorteioId] = useState(null);

  const { cliente_id, id } = useParams();

  const empresaId = item?.company_id; // Certifique-se que item está definido

  // Requisição para sorteio_screen_controller
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    const raw = JSON.stringify({
      client_id: cliente_id,
      sorteio_id: id,
    });

    fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: raw,
    })
      .then((response) => response.json())
      .then((json) => setSorteio(json || [])) // Fallback para array vazio
      .catch((err) => console.error('Erro ao buscar sorteio:', err));
  }, [cliente_id, id]);

  // Requisição para sorteio e verificação de próximo sorteio
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    const raw = JSON.stringify({
      client_id: cliente_id,
      sorteio_id: id,
    });

    fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: raw,
    })
      .then((response) => response.json())
      .then((json) => {
        setSorteio2(json || []); // Fallback para array vazio

        // Ordenar sorteios por data (mais próximos primeiro)
        const sorteiosOrdenados = json[0]?.response?.sort(
          (a, b) => new Date(a.data_sorteio) - new Date(b.data_sorteio),
        );

        console.log('Sorteios ordenados:', sorteiosOrdenados);

        // Filtrar sorteios relevantes
        const sorteiosFiltrados = sorteiosOrdenados?.filter((item) => {
          return (
            !item.sorteio_encerrado && // Não encerrado
            !item.sorteio_realizado && // Não realizado
            (item.sorteio_exclusivo === null ||
              item.sorteio_exclusivo === empresaId) // Mesma empresa ou público
          );
        });

        console.log('Sorteios filtrados:', sorteiosFiltrados);

        // Encontra o próximo sorteio válido
        const nextSorteio = sorteiosFiltrados?.find(
          (item) => item.id !== parseInt(id),
        );

        if (nextSorteio) {
          setHasNextSorteio(true);
          setNextSorteioId(nextSorteio.id); // Salva o ID do próximo sorteio
        } else {
          setHasNextSorteio(false);
        }
      })
      .catch((err) => console.error('Erro ao buscar sorteio2:', err));
  }, [cliente_id, id, empresaId]);

  // Controle do cronômetro de redirecionamento
  useEffect(() => {
    if (isCancelled || redirectTimer <= 0 || hasNextSorteio === false) return;

    const timer = setTimeout(() => {
      setRedirectTimer((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [redirectTimer, isCancelled, hasNextSorteio]);

  // Redireciona para o próximo sorteio
  useEffect(() => {
    if (!isCancelled && redirectTimer === 0 && hasNextSorteio) {
      if (nextSorteioId) {
        goToNextSorteio(nextSorteioId); // Redireciona para o próximo sorteio
      } else {
        console.error('Erro: Próximo sorteio não encontrado.');
      }
    }
  }, [
    redirectTimer,
    isCancelled,
    hasNextSorteio,
    nextSorteioId,
    goToNextSorteio,
  ]);

  // Cancela o redirecionamento
  const cancelRedirect = () => {
    setIsCancelled(true);
  };

  if (!sorteio.length || !sorteio2.length) {
    return <div>Carregando informações do sorteio...</div>;
  }

  if (!goToNextSorteio) {
    return <div>Erro: Função de redirecionamento não definida.</div>;
  }

  // Dados do sorteio
  const vencedor = sorteio2[0]?.response?.find(
    (item) => item.id === parseInt(id),
  )?.nome;
  const foto = sorteio2[0]?.response?.find(
    (item) => item.id === parseInt(id),
  )?.imagem;

  return (
    <div className="MainGrid">
      <div className="container-resultado">
        <h1>Resultado do Sorteio</h1>

        <div className="resultado">
          <h2>{vencedor || 'Vencedor não encontrado'}</h2>
          {foto ? (
            <img
              src={`${REACT_APP_URL_API}${foto}`}
              alt="Imagem do vencedor"
              style={{ width: '300px' }}
            />
          ) : (
            <img
              src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
              alt=""
              style={{ width: '300px' }}
            />
          )}
          <p className="text-ganhador">
            {sorteio[0]?.chosen_name || 'Nome do ganhador não disponível'}
          </p>
        </div>

        {/* Timer e botões */}
        {hasNextSorteio ? (
          <div className="timer-section">
            {!isCancelled && (
              <p>
                Você será redirecionado automaticamente em{' '}
                <span style={{ color: 'red' }}>{redirectTimer}</span>{' '}
                segundos...
              </p>
            )}
            {isCancelled && <p>O redirecionamento foi cancelado.</p>}
          </div>
        ) : (
          <div className="timer-section">
            <p style={{ color: 'green' }}>Não há mais sorteios para exibir.</p>
          </div>
        )}
        <div className="div_btn_next_sorteio">
          {hasNextSorteio && (
            <button
              onClick={() => {
                if (nextSorteioId) {
                  goToNextSorteio(nextSorteioId); // Redireciona para o próximo sorteio
                } else {
                  alert('Nenhum próximo sorteio disponível.');
                }
              }}
              className="btn-next-sorteio"
              style={{ background: '#36a02e' }}
            >
              Próximo sorteio
            </button>
          )}
          {!isCancelled && hasNextSorteio && (
            <button
              onClick={cancelRedirect}
              className="btn-cancelar"
              style={{ border: '1px solid red', color: 'red' }}
            >
              Cancelar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Resultado;
