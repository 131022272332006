import { Header } from '../../Components/Header';
import './style.css';
import { FcNext } from 'react-icons/fc';
import { AiTwotoneStar } from 'react-icons/ai';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import Cookies from 'universal-cookie';
import Loading from '../../Components/Loading';
import { GlobalContext } from '../../GlobalContext';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import 'react-photo-view/dist/react-photo-view.css';

const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};
export function Sorteio() {
  const { item, session, whoami } = React.useContext(GlobalContext);
  const { cliente_id } = useParams();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [participar, setParticipar] = React.useState(false);

  let [data, setData] = React.useState(null);

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: '',
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  const sorteioApi = () => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  };

  const handleParticipar = (id) => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });
    fetch(`${REACT_APP_URL_API}/apiv2/sorteio_creation/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        client_id: teste,
        sorteio_id: [id],
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        setParticipar(response.status);
      })

      .catch((erro) => console.log(erro));
    handleFechar();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 1500);

    sorteioApi();
  };

  const handleFechar = () => setAbrir(false);

  const [abrir1, setAbrir1] = React.useState(false);
  const [abrir2, setAbrir2] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleAbrir1 = () => setAbrir1(true);
  const handleFechar1 = () => setAbrir1(false);

  const handleAbrir2 = () => setAbrir2(true);
  const handleFechar2 = () => setAbrir2(false);

  let [index, setIndex] = React.useState([]);

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setIndex(json));
  }, []);

  const [abrir, setAbrir] = React.useState(0);

  const empresaId = item.company_id;

  const handleAbrir = (id) => {
    setAbrir(id);
  };

  function compare(a, b) {
    return a.data_sorteio > b.data_sorteio
      ? 1
      : a.data_sorteio < b.data_sorteio
      ? -1
      : 0;
  }

  function compare2(a, b) {
    return a.data_sorteio < b.data_sorteio
      ? 1
      : a.data_sorteio > b.data_sorteio
      ? -1
      : 0;
  }

  if (loading)
    return (
      <div>
        {' '}
        <Loading />
      </div>
    );

  if (data === null) return null;

  //Participação de sorteios simultaneos
  const filterId = data[0]?.response.filter(
    (item) => item.controle[0] == 'create',
  );

  const filterSorteioAtivo = filterId.filter((item) => !item.sorteio_encerrado);
  const filterIdAtivo = filterSorteioAtivo.map((item) => item.id);
  const numeroId = filterIdAtivo.length;

  const handleTodos = () => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    // Filtra sorteios que são exclusivos para a empresa ou sem exclusividade
    const filteredIds = filterIdAtivo.filter((id) => {
      const sorteio = filterSorteioAtivo.find((item) => item.id === id);
      return (
        sorteio.sorteio_exclusivo === null ||
        sorteio.sorteio_exclusivo === empresaId
      );
    });

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    fetch(`${REACT_APP_URL_API}/apiv2/sorteio_creation/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        client_id: teste,
        sorteio_id: filteredIds,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setParticipar(response.status);
      })
      .catch((erro) => console.log(erro));

    handleFechar();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 1500);

    sorteioApi();
  };

  return (
    <div>
      <Header />

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable prevent tabs example"
        >
          <Tab
            label="Sorteios em andamento"
            style={{ color: 'var(--secondary)' }}
          />

          <Tab
            label="Sorteios encerrados"
            style={{ color: 'var(--secondary)' }}
          />
        </Tabs>

        {value === 0 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            <div style={{ paddingBottom: '10px' }}>
              {/* <Alert severity="info">
                Informamos que, devido às regras do regulamento, não será
                possível ser sorteado em mais de um evento do mesmo dia.
              </Alert> */}
            </div>
            {data[0]?.response.length === 0 && (
              <div>
                {/* <Header /> */}
                <div className="div-stack-alert">
                  <Stack
                    sx={{ width: '85%', margin: ' auto', maxWidth: '500px' }}
                    spacing={5}
                  >
                    <Alert severity="warning">
                      Nenhum Sorteio disponível no momento.
                    </Alert>
                    <ButtonLink
                      to={`/${cliente_id}`}
                      className="btn-return-alert"
                    >
                      Voltar
                    </ButtonLink>
                  </Stack>
                </div>
              </div>
            )}
            <div>
              {data[0].response.sort(compare).map((item) => (
                <span>
                  {cliente_id === 'hashtagsal' ? (
                    <div key={item.id}>
                      {item.sorteio_encerrado == false ? (
                        <div>
                          <div className="container-card-sorteio box">
                            {item.imagem ? (
                              <PhotoProvider>
                                <PhotoView
                                  src={`${REACT_APP_URL_API}` + item.imagem}
                                >
                                  <img
                                    src={`${REACT_APP_URL_API}` + item.imagem}
                                    alt=""
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            ) : (
                              <PhotoProvider>
                                <PhotoView src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg">
                                  <img
                                    src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                    alt=""
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            )}

                            {item.controle == 'create' && (
                              <div className="conteudo-card">
                                {item.sorteio_exclusivo === empresaId && (
                                  <div
                                    className="div-exclusive-sorteio"
                                    style={{
                                      background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                    }}
                                  >
                                    <p>
                                      Sorteio exclusivo{' '}
                                      <AiTwotoneStar
                                        style={{
                                          position: 'relative',
                                          top: '2px',
                                          left: '5px',
                                          color: '#f4af08',
                                        }}
                                      />
                                    </p>
                                  </div>
                                )}

                                <h2>{item.nome}</h2>
                                <p>{item.descricao}</p>
                                <p>{item.data_sorteio}</p>
                                {item.sorteio_exclusivo === null && (
                                  <span>
                                    {item.sorteio_realizado === true ? (
                                      <ButtonLink
                                        to="#"
                                        className="btn-participar"
                                        style={{
                                          background: 'grey',
                                          opacity: '.5',
                                        }}
                                      >
                                        Indisponível
                                      </ButtonLink>
                                    ) : (
                                      <ButtonLink
                                        to="#"
                                        onClick={() => handleAbrir(item.id)}
                                        className="btn-participar"
                                      >
                                        Participar
                                      </ButtonLink>
                                    )}
                                  </span>
                                )}

                                {item.sorteio_exclusivo === empresaId && (
                                  <span>
                                    {item.sorteio_realizado === true ? (
                                      <ButtonLink
                                        to="#"
                                        className="btn-participar"
                                        style={{
                                          background: 'grey',
                                          opacity: '.5',
                                        }}
                                      >
                                        Indisponível
                                      </ButtonLink>
                                    ) : (
                                      <ButtonLink
                                        to="#"
                                        onClick={() => handleAbrir(item.id)}
                                        className="btn-participar"
                                      >
                                        Participar
                                      </ButtonLink>
                                    )}
                                  </span>
                                )}

                                {item.sorteio_exclusivo !== null &&
                                  item.sorteio_exclusivo !== empresaId && (
                                    <ButtonLink
                                      to="#"
                                      className="btn-participar"
                                      onClick={handleAbrir2}
                                    >
                                      Participar
                                    </ButtonLink>
                                  )}
                              </div>
                            )}

                            {item.controle[0] == 'show' && (
                              <div className="conteudo-card participando">
                                <h2>{item.nome}</h2>
                                <p>{item.descricao}</p>
                                <p>{item.data_sorteio}</p>
                                <a href={`${item.id}/painel`}>
                                  <span>
                                    {' '}
                                    Acompanhar sorteio <FcNext />{' '}
                                  </span>
                                </a>
                                <button className="btn-participando" id="btn">
                                  <a href="#">
                                    Você está participando, {item.controle[1]}!
                                  </a>
                                </button>
                              </div>
                            )}
                            {item.controle[0] == null && (
                              <div className="conteudo-card participando">
                                <h2>{item.nome}</h2>
                                <p>{item.descricao}</p>
                                <p>{item.data_sorteio}</p>

                                <ButtonLink
                                  to="#"
                                  onClick={handleAbrir1}
                                  className="btn-participar"
                                >
                                  Participar
                                </ButtonLink>
                              </div>
                            )}
                            <Modal
                              open={item.id === abrir}
                              onClose={handleFechar}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              id={item.id}
                            >
                              <Box sx={style}>
                                {numeroId > 4 ? (
                                  <div>
                                    <p
                                      style={{
                                        color: 'var(--title)',
                                        padding: '10px',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      Existem mais sorteios disponiveis. Deseja
                                      participar de todos?
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      {' '}
                                      <ButtonLink
                                        to="#"
                                        // onClick={handleParticipar}
                                        onClick={() => handleTodos()}
                                        className="btn-confrimar"
                                      >
                                        Todos
                                      </ButtonLink>
                                      <ButtonLink
                                        to="#"
                                        // onClick={handleParticipar}
                                        onClick={() =>
                                          handleParticipar(item.id)
                                        }
                                        className="btn-confrimar"
                                      >
                                        Somente esse
                                      </ButtonLink>
                                    </div>

                                    <button
                                      className="close"
                                      onClick={handleFechar}
                                      style={{
                                        background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <p
                                      style={{
                                        color: 'var(--title)',
                                        padding: '10px',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      Você confirma a participação no Sorteio?
                                    </p>
                                    <div>
                                      {' '}
                                      <ButtonLink
                                        to="#"
                                        // onClick={handleParticipar}
                                        onClick={() =>
                                          handleParticipar(item.id)
                                        }
                                        className="btn-confrimar"
                                      >
                                        Confirmar
                                      </ButtonLink>
                                    </div>

                                    <button
                                      className="close"
                                      onClick={handleFechar}
                                      style={{
                                        background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                )}
                              </Box>
                            </Modal>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div key={item.id}>
                      <span>
                        {item.sorteio_encerrado == false &&
                        item.sorteio_exclusivo === empresaId ? (
                          <div>
                            <div className="container-card-sorteio box">
                              {item.imagem ? (
                                // <img
                                //   src={`${REACT_APP_URL_API}` + item.imagem}
                                //   alt=""
                                // />

                                <PhotoProvider>
                                  <PhotoView
                                    src={`${REACT_APP_URL_API}` + item.imagem}
                                  >
                                    <img
                                      src={`${REACT_APP_URL_API}` + item.imagem}
                                      alt=""
                                    />
                                  </PhotoView>
                                </PhotoProvider>
                              ) : (
                                // <img
                                //   src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                //   alt="Evento"
                                // />

                                <PhotoProvider>
                                  <PhotoView src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg">
                                    <img
                                      src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                      alt=""
                                    />
                                  </PhotoView>
                                </PhotoProvider>
                              )}

                              {item.controle == 'create' && (
                                <div className="conteudo-card">
                                  {/* {item.evento_exclusivo === empresaId && (
              <div
                className="div-exclusive-sorteio"
                style={{
                  background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                }}
              >
                <p>
                  Evento exclusivo{' '}
                  <AiTwotoneStar
                    style={{
                      position: 'relative',
                      top: '2px',
                      left: '5px',
                      color: '#f4af08',
                    }}
                  />
                </p>
              </div>
            )} */}

                                  <h2>{item.nome}</h2>
                                  <p>{item.descricao}</p>
                                  <p>{item.data_sorteio}</p>

                                  {item.sorteio_exclusivo === empresaId && (
                                    <span>
                                      {item.sorteio_realizado === true ? (
                                        <ButtonLink
                                          to="#"
                                          className="btn-participar"
                                          style={{
                                            background: 'grey',
                                            opacity: '.5',
                                          }}
                                        >
                                          Indisponível
                                        </ButtonLink>
                                      ) : (
                                        <ButtonLink
                                          to="#"
                                          onClick={() => handleAbrir(item.id)}
                                          className="btn-participar"
                                        >
                                          Participar
                                        </ButtonLink>
                                      )}
                                    </span>
                                  )}

                                  {item.sorteio_exclusivo !== null &&
                                    item.sorteio_exclusivo !== empresaId && (
                                      <ButtonLink
                                        to="#"
                                        className="btn-participar"
                                        onClick={handleAbrir2}
                                      >
                                        Participar
                                      </ButtonLink>
                                    )}
                                </div>
                              )}

                              {item.controle[0] == 'show' && (
                                <div className="conteudo-card participando">
                                  <h2>{item.nome}</h2>
                                  <p>{item.descricao}</p>
                                  <p>{item.data_sorteio}</p>
                                  <a href={`${item.id}/painel`}>
                                    <span>
                                      {' '}
                                      Acompanhar sorteio <FcNext />{' '}
                                    </span>
                                  </a>

                                  <button className="btn-participando" id="btn">
                                    <span href="#">
                                      Você está participando, {item.controle[1]}
                                      !
                                    </span>
                                  </button>
                                </div>
                              )}
                              {item.controle[0] == null && (
                                <div className="conteudo-card participando">
                                  <h2>{item.nome}</h2>
                                  <p>{item.descricao}</p>
                                  <p>{item.data_sorteio}</p>

                                  <ButtonLink
                                    to="#"
                                    onClick={handleAbrir1}
                                    className="btn-participar"
                                  >
                                    Participar
                                  </ButtonLink>
                                </div>
                              )}
                              <Modal
                                open={item.id === abrir}
                                onClose={handleFechar}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                id={item.id}
                              >
                                <Box sx={style}>
                                  {numeroId > 4 ? (
                                    <div>
                                      <p
                                        style={{
                                          color: 'var(--title)',
                                          padding: '10px',
                                          marginBottom: '20px',
                                        }}
                                      >
                                        Existem mais sorteios disponiveis.
                                        Deseja participar de todos?
                                      </p>
                                      <div style={{ display: 'flex' }}>
                                        {' '}
                                        <ButtonLink
                                          to="#"
                                          // onClick={handleParticipar}
                                          onClick={() => handleTodos()}
                                          className="btn-confrimar"
                                        >
                                          Todos
                                        </ButtonLink>
                                        <ButtonLink
                                          to="#"
                                          // onClick={handleParticipar}
                                          onClick={() =>
                                            handleParticipar(item.id)
                                          }
                                          className="btn-confrimar"
                                        >
                                          Somente esse
                                        </ButtonLink>
                                      </div>

                                      <button
                                        className="close"
                                        onClick={handleFechar}
                                        style={{
                                          background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      <p
                                        style={{
                                          color: 'var(--title)',
                                          padding: '10px',
                                          marginBottom: '20px',
                                        }}
                                      >
                                        Você confirma a participação no Sorteio?
                                      </p>
                                      <div>
                                        {' '}
                                        <ButtonLink
                                          to="#"
                                          // onClick={handleParticipar}
                                          onClick={() =>
                                            handleParticipar(item.id)
                                          }
                                          className="btn-confrimar"
                                        >
                                          Confirmar
                                        </ButtonLink>
                                      </div>

                                      <button
                                        className="close"
                                        onClick={handleFechar}
                                        style={{
                                          background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>
                                  )}
                                </Box>
                              </Modal>
                            </div>
                          </div>
                        ) : null}
                      </span>

                      {/* sorteio aberto para todos  */}

                      <span>
                        {item.sorteio_encerrado == false &&
                        item.sorteio_exclusivo === null ? (
                          <span>
                            {item.sorteio_encerrado == false &&
                            item.sorteio_exclusivo === null ? (
                              <div>
                                <div className="container-card-sorteio box">
                                  {item.imagem ? (
                                    // <img
                                    //   src={`${REACT_APP_URL_API}` + item.imagem}
                                    //   alt=""
                                    // />

                                    <PhotoProvider>
                                      <PhotoView
                                        src={
                                          `${REACT_APP_URL_API}` + item.imagem
                                        }
                                      >
                                        <img
                                          src={
                                            `${REACT_APP_URL_API}` + item.imagem
                                          }
                                          alt=""
                                        />
                                      </PhotoView>
                                    </PhotoProvider>
                                  ) : (
                                    // <img
                                    //   src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                    //   alt="Evento"
                                    // />

                                    <PhotoProvider>
                                      <PhotoView src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg">
                                        <img
                                          src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                          alt=""
                                        />
                                      </PhotoView>
                                    </PhotoProvider>
                                  )}

                                  {item.controle == 'create' && (
                                    <div className="conteudo-card">
                                      {/* {item.evento_exclusivo === empresaId && (
                <div
                  className="div-exclusive-sorteio"
                  style={{
                    background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                  }}
                >
                  <p>
                    Evento exclusivo{' '}
                    <AiTwotoneStar
                      style={{
                        position: 'relative',
                        top: '2px',
                        left: '5px',
                        color: '#f4af08',
                      }}
                    />
                  </p>
                </div>
              )} */}

                                      <h2>{item.nome}</h2>
                                      <p>{item.descricao}</p>
                                      <p>{item.data_sorteio}</p>

                                      <ButtonLink
                                        to="#"
                                        onClick={() => handleAbrir(item.id)}
                                        className="btn-participar"
                                      >
                                        Participar
                                      </ButtonLink>
                                    </div>
                                  )}

                                  {item.controle[0] == 'show' && (
                                    <div className="conteudo-card participando">
                                      <h2>{item.nome}</h2>
                                      <p>{item.descricao}</p>
                                      <p>{item.data_sorteio}</p>
                                      <a href={`${item.id}/painel`}>
                                        <span>
                                          {' '}
                                          Acompanhar sorteio <FcNext />{' '}
                                        </span>
                                      </a>

                                      <button
                                        className="btn-participando"
                                        id="btn"
                                      >
                                        <span href="#">
                                          Você está participando,{' '}
                                          {item.controle[1]}!
                                        </span>
                                      </button>
                                    </div>
                                  )}

                                  {item.controle[0] == null && (
                                    <div className="conteudo-card participando">
                                      <h2>{item.nome}</h2>
                                      <p>{item.descricao}</p>
                                      <p>{item.data_sorteio}</p>

                                      <ButtonLink
                                        to="#"
                                        onClick={handleAbrir1}
                                        className="btn-participar"
                                      >
                                        Participar
                                      </ButtonLink>
                                    </div>
                                  )}
                                  <Modal
                                    open={item.id === abrir}
                                    onClose={handleFechar}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    id={item.id}
                                  >
                                    <Box sx={style}>
                                      {numeroId > 4 ? (
                                        <div>
                                          <p
                                            style={{
                                              color: 'var(--title)',
                                              padding: '10px',
                                              marginBottom: '20px',
                                            }}
                                          >
                                            Existem mais sorteios disponiveis.
                                            Deseja participar de todos?
                                          </p>
                                          <div style={{ display: 'flex' }}>
                                            {' '}
                                            <ButtonLink
                                              to="#"
                                              // onClick={handleParticipar}
                                              onClick={() => handleTodos()}
                                              className="btn-confrimar"
                                            >
                                              Todos
                                            </ButtonLink>
                                            <ButtonLink
                                              to="#"
                                              // onClick={handleParticipar}
                                              onClick={() =>
                                                handleParticipar(item.id)
                                              }
                                              className="btn-confrimar"
                                            >
                                              Somente esse
                                            </ButtonLink>
                                          </div>

                                          <button
                                            className="close"
                                            onClick={handleFechar}
                                            style={{
                                              background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      ) : (
                                        <div>
                                          <p
                                            style={{
                                              color: 'var(--title)',
                                              padding: '10px',
                                              marginBottom: '20px',
                                            }}
                                          >
                                            Você confirma a participação no
                                            Sorteio?
                                          </p>
                                          <div>
                                            {' '}
                                            <ButtonLink
                                              to="#"
                                              // onClick={handleParticipar}
                                              onClick={() =>
                                                handleParticipar(item.id)
                                              }
                                              className="btn-confrimar"
                                            >
                                              Confirmar
                                            </ButtonLink>
                                          </div>

                                          <button
                                            className="close"
                                            onClick={handleFechar}
                                            style={{
                                              background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      )}
                                    </Box>
                                  </Modal>
                                </div>
                              </div>
                            ) : null}
                          </span>
                        ) : null}
                      </span>
                    </div>
                  )}
                </span>
              ))}
            </div>
          </div>
        )}
        {value === 1 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            <span>
              {data[0]?.response.length === 0 && (
                <div>
                  {/* <Header /> */}
                  <div className="div-stack-alert">
                    <Stack
                      sx={{ width: '85%', margin: ' auto', maxWidth: '500px' }}
                      spacing={5}
                    >
                      <Alert severity="warning">
                        Nenhum Sorteio disponível no momento.
                      </Alert>
                      <ButtonLink
                        to={`/${cliente_id}`}
                        className="btn-return-alert"
                      >
                        Voltar
                      </ButtonLink>
                    </Stack>
                  </div>
                </div>
              )}
            </span>

            {data[0].response.sort(compare2).map((item) => (
              <span key={item.id}>
                {cliente_id === 'hastagsal' ? (
                  <div>
                    {item.sorteio_encerrado == true ? (
                      <div className="container-card-sorteio box">
                        {item.imagem ? (
                          // <img src={`${REACT_APP_URL_API}` + item.imagem} alt="" />

                          <PhotoProvider>
                            <PhotoView
                              src={`${REACT_APP_URL_API}` + item.imagem}
                            >
                              <img
                                src={`${REACT_APP_URL_API}` + item.imagem}
                                alt=""
                              />
                            </PhotoView>
                          </PhotoProvider>
                        ) : (
                          // <img
                          //   src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                          //   alt=""
                          // />

                          <PhotoProvider>
                            <PhotoView src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg">
                              <img
                                src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                alt=""
                              />
                            </PhotoView>
                          </PhotoProvider>
                        )}
                        <div className="conteudo-card participando">
                          <h2>{item.nome}</h2>
                          <p>{item.descricao}</p>
                          <p>{item.data_sorteio}</p>
                          {whoami.isAuthenticated !== false ? (
                            <a href={`${item.id}/ganhador`}>
                              <span>
                                {' '}
                                Conhecer o ganhador <FcNext />{' '}
                              </span>
                            </a>
                          ) : (
                            <span
                              onClick={handleAbrir1}
                              style={{ cursor: 'pointer' }}
                            >
                              {' '}
                              Conhecer o ganhador <FcNext />{' '}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {item.sorteio_exclusivo === empresaId &&
                    item.sorteio_encerrado == true ? (
                      <div>
                        <div className="container-card-sorteio box">
                          {item.imagem ? (
                            // <img src={`${REACT_APP_URL_API}` + item.imagem} alt="" />

                            <PhotoProvider>
                              <PhotoView
                                src={`${REACT_APP_URL_API}` + item.imagem}
                              >
                                <img
                                  src={`${REACT_APP_URL_API}` + item.imagem}
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            // <img
                            //   src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                            //   alt=""
                            // />

                            <PhotoProvider>
                              <PhotoView src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg">
                                <img
                                  src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          )}
                          <div className="conteudo-card participando">
                            <h2>{item.nome}</h2>
                            <p>{item.descricao}</p>
                            <p>{item.data_sorteio}</p>
                            {whoami.isAuthenticated !== false ? (
                              <a href={`${item.id}/ganhador`}>
                                <span>
                                  {' '}
                                  Conhecer o ganhador <FcNext />{' '}
                                </span>
                              </a>
                            ) : (
                              <span
                                onClick={handleAbrir1}
                                style={{ cursor: 'pointer' }}
                              >
                                {' '}
                                Conhecer o ganhador <FcNext />{' '}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <span>
                      {item.sorteio_exclusivo === null &&
                      item.sorteio_encerrado == true ? (
                        <div className="container-card-sorteio box">
                          {item.imagem ? (
                            // <img src={`${REACT_APP_URL_API}` + item.imagem} alt="" />

                            <PhotoProvider>
                              <PhotoView
                                src={`${REACT_APP_URL_API}` + item.imagem}
                              >
                                <img
                                  src={`${REACT_APP_URL_API}` + item.imagem}
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            // <img
                            //   src="https://www.sindsemp-ma.org.br/novo/wp-content/uploads/2021/12/sorteio.jpg"
                            //   alt=""
                            // />

                            <PhotoProvider>
                              <PhotoView src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg">
                                <img
                                  src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          )}
                          <div className="conteudo-card participando">
                            <h2>{item.nome}</h2>
                            <p>{item.descricao}</p>
                            <p>{item.data_sorteio}</p>
                            {whoami.isAuthenticated !== false ? (
                              <a href={`${item.id}/ganhador`}>
                                <span>
                                  {' '}
                                  Conhecer o ganhador <FcNext />{' '}
                                </span>
                              </a>
                            ) : (
                              <span
                                onClick={handleAbrir1}
                                style={{ cursor: 'pointer' }}
                              >
                                {' '}
                                Conhecer o ganhador <FcNext />{' '}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                )}
              </span>
            ))}
          </div>
        )}
      </Box>

      <Modal
        open={abrir1}
        onClose={handleFechar1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p
              style={{
                color: 'var(--title)',
                padding: '10px',
                marginBottom: '20px',
              }}
            >
              Você não está logado
            </p>
            <div>
              <ButtonLink to={`/${cliente_id}/auth`}> Fazer login</ButtonLink>
            </div>
            <button
              className="close"
              onClick={handleFechar1}
              style={{
                background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
              }}
            >
              X
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={abrir2}
        onClose={handleFechar2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {cliente_id === 'hashtagsal' ? (
          <Box sx={style}>
            <div>
              <p
                style={{
                  color: 'var(--title)',
                  padding: '10px',
                  marginBottom: '20px',
                }}
              >
                Sorteio Exclusivo para apoiadores acima de R$10,00. Acesse o
                Apoia-se para apoiar ou atualise o seu apoio agora.
              </p>

              <div>
                <div className="box-button">
                  <a
                    href="https://apoia.se/hashtagsal"
                    target="_blank"
                    className="button-voucher"
                    style={{
                      background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                    }}
                  >
                    Acessar
                  </a>
                </div>
              </div>

              <button
                className="close"
                onClick={handleFechar2}
                style={{
                  background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                }}
              >
                X
              </button>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div>
              <p
                style={{
                  color: 'var(--title)',
                  padding: '10px',
                  marginBottom: '20px',
                }}
              >
                Sorteio Exclusivo entre em contato com o administrador e saiba
                mais
              </p>

              <button
                className="close"
                onClick={handleFechar2}
                style={{
                  background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                }}
              >
                X
              </button>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
}
