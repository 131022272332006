const { REACT_APP_URL_API } = process.env;

export const login = (event, state) => {
  fetch(`${REACT_APP_URL_API}/apiv2/login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': this.state.csrf,
    },

    credentials: 'include',
    body: JSON.stringify({
      username: this.state.username,
      password: this.state.password,
    }),
    redirect: 'follow',
  })
    .then(this.isResponseOk)
    .then((data) => {
      // console.log('log2', data);
      localStorage.setItem('@user', data.isAuthenticated);
      window.location.replace('home');
      this.setState({
        isAuthenticated: true,
        username: '',
        password: '',
        error: '',
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logout = async () => {
  try {
    // Chamada à API de logout
    const response = await fetch(`${REACT_APP_URL_API}/apiv2/logout/`, {
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Falha no logout. Tente novamente.');
    }

    // Caso o logout seja bem-sucedido
    localStorage.removeItem('@user');
    localStorage.removeItem('email');
    localStorage.removeItem('password');

    // Atualize o estado e redirecione para "home"
    // Certifique-se de que "setState" esteja disponível ou remova esta parte se for um componente funcional
    window.location.replace('home');
  } catch (err) {
    console.error('Erro durante o logout:', err);
    // Adicione lógica de tratamento de erro aqui, se necessário
  }
};

export const getCSRF = () => {
  fetch(`${REACT_APP_URL_API}/apiv2/csrf/`, {
    credentials: 'include',
  })
    .then((res) => {
      let csrfToken = res.headers.get('X-CSRFToken');
      this.setState({ csrf: csrfToken });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSession = () => {
  fetch(`${REACT_APP_URL_API}/apiv2/session/`, {
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      if (data.isAuthenticated) {
        this.setState({ isAuthenticated: true });
        window.location.replace('home');
        localStorage.setItem('@user', data.isAuthenticated);
      } else {
        this.setState({ isAuthenticated: false });
        this.getCSRF();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
